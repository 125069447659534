<template>
  <div>
    <h1 class="text-size-h1 text-bold mb-m">{{ $metaInfo.title }}</h1>
    <div v-if="pending">
      <BaseSpinner brand />
    </div>
    <div v-else>
      <div v-if="mentors.length">
        <div class="row mentors-list cards-list">
          <div
            v-for="mentor in mentors"
            :key="mentor.id"
            class="col-md-6 col-xs-12 col-sm-6 cards-list__item">
            <MentorCard
              :avatar="mentor.avatar"
              class="cards-list__card">
              <template #name
                >{{ mentor.first_name }} {{ mentor.last_name }}</template
              >
              <template #info>{{ mentor.organization }}</template>
              <span>{{ mentor.email }}</span>
            </MentorCard>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6 cards-list__item">
            <a
              href="#"
              class="mentors-list__btn"
              @click.prevent="$modal.show('invite-mentor')"
              >Пригласить наставника</a
            >
          </div>
        </div>
      </div>
      <div v-else>
        <p>
          У вас пока нет наставников.
          <a
            href="#"
            class="link link--pseudo"
            @click.prevent="$modal.show('invite-mentor')"
            >Пригласить наставника</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MODAL_DYNAMIC_DEFAULTS } from "@/constants";
import MentorCard from "@/components/user/MentorCard";
import { request } from "@/services/api";
import MentorRating from "@/components/user/modals/MentorRating";

export default {
  name: "UserMentors",
  components: { MentorCard },
  metaInfo() {
    return {
      title: "Мои наставники",
    };
  },
  data() {
    return {
      pending: true,
    };
  },
  computed: {
    mentors() {
      return Object.values(this.$store.state.participant.mentors);
    },
    profiles() {
      return this.$store.state.profile.profiles;
    },
    ...mapGetters({
      selectedList: "participant/selectedList",
      user: "user/user",
      firstStage: "participant/firstStage",
    }),
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.pending = true;
      await this.$store.dispatch("participant/getMentors");
      if (this.$route.query.feedback) {
        this.openMentorRatingModal();
      }
      this.pending = false;
    },
    async openMentorRatingModal() {
      const { mentors } = this;
      try {
        let mentorsList = [...mentors];
        // Если наставников нет - то и оценивать некого
        // if (!mentorsList.length) return;
        // 2. Получим список оценок, если оценили не всех наставников
        // то покажем модалку
        const { data } = await request({
          method: "GET",
          url: "/mentor/ratings",
        });
        if (Array.isArray(data?.items)) {
          const ids = data.items.map((n) => n.mentor_id);
          mentorsList = mentorsList.filter((n) => !ids.includes(n.id));
        }
        this.$modal.show(
          MentorRating,
          {
            mentors: mentorsList,
            stageNumber: 1,
            stage: this.firstStage.stage,
            profiles: this.selectedList?.reduce((acc, n) => {
              const profile = this.profiles[n.profile_id];
              profile &&
                acc.push({
                  id: profile.id,
                  title: profile.title,
                });
              return acc;
            }, []),
          },
          MODAL_DYNAMIC_DEFAULTS
        );
      } catch (error) {
        // do nothing
      }
    },
  },
};
</script>

<style lang="less">
.mentors-list {
  &__btn {
    height: 93px;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @link-color;
    border: 1px solid;
    border-radius: @radius-xl;
    text-decoration: none;
    transition: background 0.3s, color 0.3s;

    &:hover {
      color: #fff;
      border-color: @link-color;
      background-color: @link-color;
    }
  }
}
</style>
