<template>
  <div class="m-card">
    <div class="m-card__image">
      <BaseAvatar
        :image="avatar"
        class="m-card__avatar" />
    </div>
    <div class="m-card__data">
      <div class="m-card__title"><slot name="name"></slot></div>
      <div class="m-card__info"><slot name="info"></slot></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MentorCard",
  props: {
    avatar: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.m-card {
  background-color: #fff;
  overflow: hidden;
  border-radius: @radius-xl;
  box-shadow: @shadow-default;
  padding: 16px 18px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  .fz(14);

  &__avatar {
    box-shadow: @shadow-default;
  }

  &__image {
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    margin-right: 20px;
  }

  &__title {
    .fz(18);
    line-height: 24/18;
    font-weight: bold;
  }

  &__info {
    .fz(12);
  }

  &__data {
    flex-grow: 1;
  }
}
</style>
